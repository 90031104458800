import React from "react";

const App = () => {
  return (
    <div>
      <h1>Tim Vodovnik</h1>
      <p>Lep, pameten in razgledan.</p>
    </div>
  );
};

export default App;
